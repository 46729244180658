if ( window.name != 'ytFloatingCart' ) {
	var YTIMES = YTIMES || {};
	YTIMES.stats = {};
	YTIMES.stats.storeDomain	= 'trophycentral.com';
	YTIMES.stats.storeId		= 'sportsawards';
	YTIMES.stats.scriptUrl		= 'https://site.trophycentral.com/ytimesStatistics/';
	YTIMES.stats.pageId			= window.location.pathname.split('/').slice(-1)[0];
	YTIMES.stats.searchId		= 'form[action*="search-results.html"]'; // search form selector
	YTIMES.cookies = YTIMES.cookies || {
		get: function(name) {
			var start = document.cookie.indexOf( name + "=" );
			var len = start + name.length + 1;
			if ( ( !start ) && ( name != document.cookie.substring( 0, name.length ) ) ) {
				return null;
			}
			if ( start == -1 ) return null;
			var end = document.cookie.indexOf( ";", len );
			if ( end == -1 ) end = document.cookie.length;
			return unescape( document.cookie.substring( len, end ) );
		},
		set: function(name,value,expires,path,domain,secure) {
			var today = new Date();
			today.setTime( today.getTime() );
			if ( expires && typeof(expires)=='number' )
			{
				expires = expires * 1000 * 60 * 60 * 24;
			}
			var expires_date;
			if (typeof(expires)=='number')
				expires_date = new Date( today.getTime() + (expires) );
			else
				expires_date = expires;
			document.cookie = name + "=" +escape( value ) +
				( ( expires ) ? ";expires=" + expires_date.toGMTString() : "" ) +
				( ( path ) ? ";path=" + path : "" ) + 
				( ( domain ) ? ";domain=" + domain : "" ) + ";secure;sameSite=None";
		},
		delete: function(name,path,domain) {
			if ( YTIMES.cookies.get( name ) ) {
				document.cookie = name + "=" +
				( ( path ) ? ";path=" + path : "") +
				( ( domain ) ? ";domain=" + domain : "" ) +
				";expires=Thu, 01-Jan-1970 00:00:01 GMT";
			}
		}
	};
	YTIMES.stats.createVS = function() {
		let newvsid = 'ytstvs_' + Math.random().toString(36).substring(2);
		YTIMES.cookies.set('ytst-vsid',newvsid,30,'/','.'+YTIMES.stats.storeDomain);
		return newvsid;
	};
	YTIMES.stats.createSS = function() {
		let newssid = Math.random().toString(36).substring(2);
		YTIMES.cookies.set('ytst-ssid',newssid,null,'/','.'+YTIMES.stats.storeDomain);
		return newssid;
	};
	YTIMES.stats.sendEvent = function(event,params) {
		let ytssData = new FormData();
		ytssData.append('stid', YTIMES.stats.storeId);
		ytssData.append('vsid', YTIMES.stats.visitorId);
		ytssData.append('ssid', YTIMES.stats.sessionId);
		ytssData.append('pid', YTIMES.stats.pageId);
		ytssData.append('event', event);
		if ( params !== null && typeof(params) != 'undefined' ) {
			ytssData.append('params', params);
		}
		fetch(YTIMES.stats.scriptUrl+'ytimes-stats.php', {
			method: "POST",
			body: ytssData
		}).then(res => {
			console.log("YTSS.resp:",res);
		});
	};
	YTIMES.stats.visitorId = YTIMES.cookies.get('ytst-vsid') || YTIMES.stats.createVS();
	YTIMES.stats.sessionId = YTIMES.cookies.get('ytst-ssid') || YTIMES.stats.createSS();

	YTIMES.stats.sendEvent("page-view");
	window.onclick = e => {
		if (e.isTrusted) {
			let eldata = '{"elem":"'+e.target.tagName+'","class":"'+e.target.className+'","id":"'+e.target.id+'"}';
			YTIMES.stats.sendEvent("click",eldata);
		}
	}
	// TRACK SEARCH SUBMIT
	if ( !!document.querySelector(YTIMES.stats.searchId) ) {
		document.querySelector(YTIMES.stats.searchId).addEventListener('submit', function() {
			var ifield = this.querySelector('input[type="text"]');
			let eldata = '{"searchkw": "' + ifield.value + '"}';
			YTIMES.stats.sendEvent("search", eldata);
		});
	}
	// CART PAGE
	if( document.getElementById("ys_cartPage") && numOfItems > 0 ) {
		var itemDiff = items.length - sessionStorage.getItem('ytst-cart-count');
		var isNewItem = ( itemDiff > 0 ? true : false );
		if ( isNewItem ) {
			sessionStorage.setItem('ytst-cart-count',items.length);
			var lastIndex = items.length - 1;
			var sdata = '{"items":[';
			for (i=lastIndex - 1;i>=0;i--) {
				if ( i < lastIndex ) {
					sdata += ',';
				}
				sdata += '{"pid":"' + items[i] + '","qty":' + parseInt(qtys[i]) + ',"id":' + parseFloat(price[i]) + '}';
			}
			sdata += ']}';
			YTIMES.stats.sendEvent("add-to-cart",sdata);
		}
	}
	// CHECKOUT PAGE
	if( document.getElementById("ys_shipBillPage") ) {
		if ( sessionStorage.getItem('ytst-cart-count') == null ) {
			sessionStorage.setItem('ytst-cart-count',items.length);
			var lastIndex = items.length - 1;
			var sdata = '{"items":[';
			for (var i=lastIndex - 1;i>=0;i--) {
				if ( i < lastIndex ) {
					sdata += ',';
				}
				sdata += '{"pid":"' + items[i] + '","qty":' + parseInt(qtys[i]) + ',"id":' + parseFloat(price[i]) + '}';
			}
			sdata += ']}';
			YTIMES.stats.sendEvent("add-to-cart",sdata);
		}
		var sdata = '{"cartValue": ' + parseFloat(orderTotal) + ', "items":[';
		for (i=0;i<items.length;i++) {
			if ( i > 0 ) {
				sdata += ',';
			}
			sdata += '{"pid":"' + items[i] + '","qty":' + parseInt(qtys[i]) + ',"price":' + parseFloat(price[i]) + '}';
		}
		sdata += ']}';
		YTIMES.stats.sendEvent("begin-checkout",sdata);
	}
	// SINGLE PAGE
	if( document.getElementById("ys_onePage") && numOfItems > 0 ) {
		// SEND ADD TO CART EVENT IF APPLICABLE
		var itemDiff = items.length - sessionStorage.getItem('ytst-cart-count');
		var isNewItem = ( itemDiff > 0 ? true : false );
		if ( isNewItem ) {
			sessionStorage.setItem('ytst-cart-count',items.length);
			var lastIndex = items.length - 1;
			var sdata = '[';
			for (i=lastIndex - 1;i>=0;i--) {
				if ( i < lastIndex ) {
					sdata += ',';
				}
				sdata += '{"pid":"' + items[i] + '","qty":' + parseInt(qtys[i]) + ',"id":' + parseFloat(price[i]) + '}';
			}
			sdata += ']';
			YTIMES.stats.sendEvent("add-to-cart",sdata);
		}

		// SEND BEGIN CHECKOUT EVENT
		var sdata = '{"cartValue": ' + parseFloat(orderTotal) + ', "items":[';
		for (i=0;i<items.length;i++) {
			if ( i > 0 ) {
				sdata += ',';
			}
			sdata += '{"pid":"' + items[i] + '","qty":' + parseInt(qtys[i]) + ',"price":' + parseFloat(price[i]) + '}';
		}
		sdata += ']}';
		YTIMES.stats.sendEvent("begin-checkout",sdata);
	}
	// REVIEW PAGE
	if( document.getElementById("ys_reviewPage") ) {
		var sdata = '{"cartValue": ' + parseFloat(orderTotal) + ', "items":[';
		for (i=0;i<items.length;i++) {
			if ( i > 0 ) {
				sdata += ',';
			}
			sdata += '{"pid":"' + items[i] + '","qty":' + parseInt(qtys[i]) + ',"price":' + parseFloat(price[i]) + '}';
		}
		sdata += ']}';
		YTIMES.stats.sendEvent("review-checkout",sdata);
	}
	// CONFIRMATION PAGE
	if( document.getElementById("ys_confirmationPage") ) {
		var sdata = '{"orderNumber": ' + orderNum.replace(YTIMES.stats.storeId+'-','') + ',"orderValue":' + parseFloat(orderTotal) + '"tax":' + parseFloat(orderTax) + ',"shipping":' + parseFloat(orderShipping) + ',"discount":' + Math.abs(parseFloat(orderDiscount)) + ', "items":[';
		for (i=0;i<items.length;i++) {
			if ( i > 0 ) {
				sdata += ',';
			}
			sdata += '{"pid":"' + items[i] + '","qty":' + parseInt(qtys[i]) + ',"price":' + parseFloat(price[i]) + '}';
		}
		sdata += ']}';
		YTIMES.stats.sendEvent("purchase",sdata);
	}
	// STATUS PAGE
	if( document.getElementById("ys_statusPage") ) {
	}
}